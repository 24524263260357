import React, { useCallback } from 'react';
// material-ui components
import { makeStyles, CircularProgress, Button } from '@material-ui/core';
// import components
import IconButtonService from 'components/Service/iconButtonService';
import DashboardExpensionPanel from 'components/DashboardExpensionPanel';
import { urls } from 'components/Service/serviceHelper';
import { isKoba } from 'services/Auth';

const useStyles = makeStyles(_theme => ({
  services: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '30px',
  },
  loader: {
    margin: 'auto',
  },
}));

/**
 * Services Container
 */
const Services = ({ services, isLoaded }) => {
  const classes = useStyles();

  const switchToStatisticsPage = () => {
    window.location.href = `${urls.citizen}/admin`;
  };

  const panelContent = useCallback(
    () => (
      <div className={classes.services}>
        {services.map(service => (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButtonService key={service.kind} data={service} />
            {service.kind === 'citizen' && isKoba() ? (
              <Button className="statButton" onClick={switchToStatisticsPage}>
                Statistiques
              </Button>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    ),
    [services, classes],
  );

  const loader = (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );

  return (
    <DashboardExpensionPanel
      kind="services"
      title="Mes services"
      content={isLoaded ? panelContent() : loader}
    />
  );
};

export default Services;
