import React from 'react';
import ActiveService from './activeService';
import UnactiveService from './unactiveService';

/**
 * Service Component
 */
const IconButtonService = ({ data }) => {
  return data.activeServices.length ? <ActiveService {...data} /> : <UnactiveService {...data} />;
};

export default IconButtonService;
