import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import Axios from './Axios';
import refreshAxiosInstance from './RefreshAxios';
import { getCookie } from './Cookie';
import { getCurrentTimestamp } from './Utilities';

export const createAuthSession = async credentials => Axios.post('/login', credentials);
export const register = params => Axios.post('/signup', params);
export const refreshToken = () => refreshAxiosInstance.get('/refresh');
export const logout = () => Axios.delete('/logout');

export const setPersonalInformations = ({ firstname, lastname, email }) => {
  localStorage.setItem('fullname', `${firstname} ${lastname}`);
  localStorage.setItem('email', email);
};

/*
  Available claims :
  ["authUserId", "fullname", "scp", "sub",
   "kind", "iat", "exp", "jti", "clients"]
*/
export const getToken = () => getCookie('token');
export const getTokenClaims = () => jwtDecode(getToken());
export const getFullName = () => localStorage.getItem('fullname');

export const isKoba = () => {
  try {
    const token = jwtDecode(getCookie('token'));
    return token.kind === 'koba';
  } catch (_e) {
    return false;
  }
};

export const isTokenExpired = () => {
  const { exp } = getTokenClaims();
  const now = getCurrentTimestamp();
  return exp - now < 0;
};

export const isLoggedIn = () => {
  try {
    return !!(getCookie('token') && !isTokenExpired());
  } catch (_e) {
    return false;
  }
};

export const login = async credentials =>
  createAuthSession(credentials)
    .then(authResponse => {
      // If the cookie is not set by the API, throw an error (SameSite)
      if (!getCookie('token')) {
        throw new Error('Veuillez contacter le service client');
      }
      const { data } = authResponse;
      setPersonalInformations(data);

      return authResponse;
    })
    .catch(_e => {
      throw new Error('Adresse e-mail ou mot de passe incorrect.');
    });
