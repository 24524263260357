import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { HexColorPicker } from 'react-colorful';
import 'react-colorful/dist/index.css';

const useStyles = makeStyles(theme => ({
  error: {
    color: '#f44336',
    fontSize: '0.75rem',
    display: 'block',
    marginLeft: '14px',
  },
  cgv: {
    color: '#000000',
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4A97FF',
    color: '#FFFFFF',
  },
  colorContainer: {
    display: 'inline-block',
    height: 15,
    width: 15,
    verticalAlign: 'middle',
  },
  primaryColor: ({ primary_color }) => ({
    background: primary_color,
  }),
  secondaryColor: ({ secondary_color }) => ({
    background: secondary_color,
  }),
}));

const StepTwoFreemium = ({ freemium, errors, handleChange, setFreemium, setActiveStep }) => {
  const { primary_color, secondary_color, cgv } = freemium;
  const classes = useStyles({ primary_color, secondary_color });
  const { logo: errorlogo } = errors;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <p>
            Couleur primaire :{' '}
            <span className={`${classes.colorContainer} ${classes.primaryColor}`} />
          </p>
          <HexColorPicker
            color={primary_color}
            onChange={primary_color =>
              setFreemium(prevFreemium => ({ ...prevFreemium, primary_color }))
            }
          />
        </Grid>
        <Grid item xs>
          <p>
            Couleur secondaire :{' '}
            <span className={`${classes.colorContainer} ${classes.secondaryColor}`} />
          </p>
          <HexColorPicker
            color={secondary_color}
            onChange={secondary_color =>
              setFreemium(prevFreemium => ({ ...prevFreemium, secondary_color }))
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <p>Logo de votre commune *</p>
          <span className={classes.pictureInfo}>
            La taille maximum est de 2 Mo. Afin d&apos;avoir un rendu optimal, nous vous suggérons
            d&apos;utiliser un fichier PNG.
          </span>
          <input type="file" name="header" onChange={handleChange} />
          <span className={classes.error}>{errorlogo}</span>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={<Checkbox checked={cgv} onChange={handleChange} name="cgv" color="primary" />}
            label={
              <div>
                <span>J&apos;accepte les </span>
                <a
                  className={classes.cgv}
                  rel="noopener noreferrer"
                  href="https://www.koba-civique.com/conditions-generales-de-ventes-freemium/"
                  target="_blank"
                >
                  conditions générales de vente
                </a>
              </div>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.button}
            type="submit"
            fullWidth
            disabled={!cgv}
          >
            Créer mon site
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" onClick={() => setActiveStep(0)} fullWidth>
            Etape précédente
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p>* champs obligatoires</p>
        </Grid>
      </Grid>
    </>
  );
};

StepTwoFreemium.propTypes = {
  freemium: PropTypes.object.isRequired,
  setFreemium: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default StepTwoFreemium;
