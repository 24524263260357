import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
// material-ui components
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';

// MakeStyles
const useStyles = makeStyles(theme => ({
  statistic: {
    margin: theme.spacing(1),
    borderColor: props => props.color || '#FFF',
    width: props => (props.width ? props.width : 250),
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    border: '1px',
    borderStyle: 'solid',
  },
  content: {
    padding: '0',
    margin: '0',
    '&:last-child': {
      padding: '0',
    },
  },
  title: {
    backgroundColor: props => props.color || '#FFF',
    fontSize: '1rem',
    color: '#FFF',
    height: '2rem',
    lineHeight: '2rem',
    paddingLeft: theme.spacing(2),
    borderRadius: '0 0 4px 4px',
  },
}));

const dataMap = {
  'ga:sessions': 0,
  'ga:pageviews': 1,
  'ga:avgSessionDuration': 2,
  'ga:users': 3,
};

/**
 * StatisticAnalitycs Component
 */
const StatisticAnalitycs = props => {
  const classes = useStyles(props);
  const { data, title, width, dataset } = props;

  const [chart, setChart] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        fill: true,
        lineTension: 0.3,
        fillOpacity: 0.3,
        backgroundColor: 'rgba(108,104,173,0.2)',
        borderColor: 'rgba(108,104,173,1)',
        borderWidth: 1,
        borderDash: [],
        pointBorderColor: 'rgba(108,104,173,1)',
        pointHoverBackgroundColor: 'rgba(108,104,173,1)',
        pointHoverBorderColor: 'rgba(108,104,173,1)',
        pointRadius: 1,
        data: [],
      },
    ],
  });

  useEffect(() => {
    if (!data.length) return;

    const dimensions = data.flatMap(element => element.dimensions);
    const metrics = data
      .flatMap(element => element.metrics)
      .map(element => element.values[dataMap[dataset]]);

    setChart(prevChart => ({
      ...prevChart,
      labels: dimensions,
      datasets: [{ ...prevChart.datasets[0], data: metrics }],
    }));
  }, [data, dataset]);

  return (
    <Card className={classes.statistic}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h2" className={classes.title}>
          {title || <Skeleton width={width || 250} />}
        </Typography>
        {chart ? (
          <Line data={chart} />
        ) : (
          <Skeleton variant="rect" height={200} width={width || 250} />
        )}
      </CardContent>
    </Card>
  );
};
export default StatisticAnalitycs;
