import { FormControl, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';
import { formatInternalLink } from './serviceHelper';

const useStyles = makeStyles(_theme => {
  return {
    select: {
      textAlign: 'center',
      position: 'absolute',
      top: '90%',
      left: '25%',
    },
  };
});

const ServiceSelect = ({ activeServices, isOpen, onClose }) => {
  const classes = useStyles();
  if (!isOpen) return null;

  return (
    <FormControl className={classes.select}>
      <Select open onClose={onClose} value="" onChange={e => window.open(e.target.value, '_blank')}>
        {activeServices.map(instance => (
          <MenuItem key={instance.id} value={formatInternalLink(instance)}>
            <Typography variant="body2" align="center" color="textSecondary" component="p">
              {instance.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ServiceSelect;
