import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// material-ui components
import { Grid, makeStyles } from '@material-ui/core';
// Import assets
import backgroundTownhall from 'assets/images/background.jpg';
// import components
import LoginForm from './LoginForm';
import ResetForm from './ResetForm';
import ForgotForm from './ForgotForm';
import RegisterForm from './RegisterForm';
import FreemiumForm from './Freemium/Website';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  picture: {
    backgroundImage: `url(${backgroundTownhall})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    height: '100%',
  },
  login: {
    height: '100%',
  },
  logo: {
    width: '40%',
  },
  h1: {
    color: '#165267',
    marginBottom: theme.spacing(8),
    fontFamily: 'Pacifico,cursive',
    fontSize: '2.5rem',
  },
  h2: {
    color: '#165267',
    fontSize: '1.5rem',
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  textfield: {
    margin: theme.spacing(2, 0, 2),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4A97FF',
    color: '#FFFFFF',
  },
  text: {
    color: '#5B6B75',
  },
  footer: {
    position: 'absolute',
    width: 'auto',
    bottom: 0,
  },
}));

/**
 * News Container
 */
const Login = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.login}
      >
        <Grid item xs={false} md={7} className={classes.picture} />
        <Grid item xs md={5}>
          <Switch>
            <Route exact path="/reset/:token" component={ResetForm} />
            <Route exact path="/register" component={RegisterForm} />
            <Route exact path="/forgot" component={ForgotForm} />
            <Route exact path="/freemium" component={FreemiumForm} />
            <Route exact path="/offre" component={FreemiumForm} />
            <Route exact path={path} component={LoginForm} />
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};
export default Login;
