import { useCallback, useEffect, useState } from 'react';
import { fetchServices } from 'services/Instances';

// Logos
import siteLogo from 'assets/images/site.png';
import workspaceLogo from 'assets/images/workspace.png';
import campaignLogo from 'assets/images/campaign.png';
import copernicLogo from 'assets/images/copernic.png';
import eticketLogo from 'assets/images/eticket.png';
import electionLogo from 'assets/images/election.png';
import citizenlogo from 'assets/images/citizen.png';

const serviceData = [
  { kind: 'site', title: 'Mon site', image: siteLogo, internal: true },
  { kind: 'campaign', title: 'Ma communication', image: campaignLogo, internal: true },
  { kind: 'workspace', title: 'Mon espace collaboratif', image: workspaceLogo, internal: true },
  { kind: 'eticket', title: 'Mon espace famille', image: eticketLogo, internal: false },
  { kind: 'election', title: 'Koba election', image: electionLogo, internal: false },
  { kind: 'copernic', title: 'Mes consultations', image: copernicLogo, internal: false },
  { kind: 'citizen', title: 'Mon app citoyen', image: citizenlogo, internal: true },
];

const sortServices = (a, b) => {
  if (a.activeServices.length && !b.activeServices.length) return -1;
  if (!a.activeServices.length && b.activeServices.length) return 1;
  return 0;
};

const useServices = () => {
  const [services, setServices] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const orderedServices = useCallback(() => {
    return serviceData
      .map(service => {
        const activeServices = services.filter(userService => userService.kind === service.kind);
        if (activeServices.length > 0) {
          // Store all slugs in localStorage for Statistics
          if (!localStorage.getItem(`${service.kind}_slug`)) {
            localStorage.setItem(`${service.kind}_slug`, activeServices[0].slug);
          }
        }
        return { ...service, activeServices };
      })
      .sort(sortServices);
  }, [services]);

  useEffect(() => {
    fetchServices()
      .then(({ data }) => {
        setServices(data);
        setIsLoaded(true);
      })
      .catch(() => setServices([]));
  }, []);

  return {
    services: orderedServices(),
    isLoaded,
  };
};

export default useServices;
