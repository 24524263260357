import React from 'react';
// material-ui components
import { makeStyles } from '@material-ui/core';

// MakeStyles
const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: theme.spacing(4),
    textAlign: 'center',
    width: '100%',
  },
  info: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
  },
  email: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
  },
  phone: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
  },
}));

/**
 * MenuSupport Component
 */
const MenuSupport = () => {
  const { root, info, email } = useStyles();

  return (
    <div className={root}>
      <p>Vous avez une question ?</p>
      <p className={info}>
        Toute l&apos;équipe du Succès Client <br />
        vous répond :
      </p>
      <p className={email}>
        <b>Email : </b>
        <a href="mailto:succes.client@koba.com">succes.client@koba.com</a>
      </p>
    </div>
  );
};
export default MenuSupport;
