import React from 'react';
import { makeStyles, Typography, List } from '@material-ui/core';

import MenuProductElement from './ProductElement';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.25rem',
    color: '#005369',
    textTransform: 'uppercase',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(0),
  },
  productsList: {
    paddingBottom: 0,
  },
}));

const MenuProduct = ({ services }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography align="center" className={classes.title}>
        Services
      </Typography>
      <List className={classes.productsList}>
        {services.map(service => (
          <MenuProductElement key={`menu-product-${service.kind}`} service={service} />
        ))}
      </List>
    </div>
  );
};

export default MenuProduct;
