import { FormHelperText, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { complexityTextMapping } from 'hooks/UsePasswordWithComplexity';

const useStyles = makeStyles(_theme => ({
  completedComplexity: {
    color: '#1fde91',
    textDecoration: 'line-through',
  },
}));

const PasswordHelper = ({ passwordComplexity }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      {Object.keys(complexityTextMapping).map(name => (
        <Grid item key={`grid-item-${name}`}>
          <FormHelperText
            className={(passwordComplexity[name] && classes.completedComplexity) || ''}
            key={`form-helper-${name}`}
          >
            &bull; {complexityTextMapping[name]}
          </FormHelperText>
        </Grid>
      ))}
    </Grid>
  );
};

export default PasswordHelper;
