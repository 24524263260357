import React, { useState } from 'react';
import Axios from 'services/Axios';

// Material-ui
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  Container,
  Link as MaterialLink,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// Components
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Link } from 'react-router-dom';

// MakeStyle
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  textfield: {
    margin: theme.spacing(1, 0, 1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4A97FF',
    color: '#FFFFFF',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  loginLink: {
    color: '#5B6B75',
    textDecoration: 'underline',
  },
  registerLink: {
    color: '#FF4F03',
  },
  registerText: {
    color: '#5B6B75',
  },
}));

/**
 * ForgotForm Container
 */
const ForgotForm = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const user = { email };

  const submitEmail = () => {
    Axios.post('/password', { user }).finally(() => setEmailSent(true));
  };

  return (
    <>
      <Header title="Mot de passe oublié" />
      <Container className={classes.paper}>
        {emailSent === false && (
          <form className={classes.form}>
            <TextField
              className={classes.textfield}
              id="email"
              name="email"
              label="Adresse email"
              variant="outlined"
              onChange={event => setEmail(event.target.value)}
              required
              fullWidth
            />
            <Button variant="contained" fullWidth onClick={submitEmail} className={classes.button}>
              Valider
            </Button>
          </form>
        )}
        {emailSent && (
          <Alert severity="info" className={classes.alert}>
            Si votre adresse existe dans notre base, vous recevrez un email d&apos;instructions pour
            réinitialiser votre mot de passe.
          </Alert>
        )}
        <Grid container>
          <Grid item xs>
            <MaterialLink component={Link} to="/" className={classes.loginLink}>
              Retour
            </MaterialLink>
          </Grid>
          <Grid item xs>
            <Typography className={classes.registerText}>
              Nouveau client ?{' '}
              <MaterialLink component={Link} to="/register" className={classes.registerLink}>
                Inscrivez-vous
              </MaterialLink>
            </Typography>
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </>
  );
};
export default ForgotForm;
