import Axios from './Axios';

const subscribeFreemium = formdata =>
  Axios.post('/trials/freemium_marketing', formdata, {
    withCredentials: false,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export { subscribeFreemium as default };
