import React, { useState } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import IconLock from '@material-ui/icons/Lock';
import ServiceSelect from 'components/Service/serviceSelect';
import { informations } from 'components/Service/serviceHelper';
import ServiceInformation from 'components/Service/serviceInformation';

const useStyles = makeStyles(_theme => ({
  lock: {
    position: 'absolute',
    top: '2px',
    left: '54px',
    fontSize: '1.12em',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  unactive: {
    opacity: 0.4,
  },
  unactiveList: {
    backgroundColor: '#DBDBDBBF',
    paddingTop: 0,
  },
}));

const MenuProductElement = ({ service }) => {
  const [listOpen, setListOpen] = useState(false);
  const classes = useStyles();
  const opacityClass = !service.activeServices.length ? classes.unactive : '';

  return (
    <>
      <ListItem
        button
        onClick={() => setListOpen(prevListOpen => !prevListOpen)}
        key={`listItem-${service.kind}`}
      >
        {!service.activeServices.length && <IconLock className={classes.lock} />}
        <ListItemAvatar key={`listItemAvatar-${service.kind}`} className={opacityClass}>
          <Avatar variant="square" src={service.image} />
        </ListItemAvatar>
        <ListItemText
          secondary={service.title}
          key={`listItemText-${service.kind}`}
          className={opacityClass}
        />
      </ListItem>
      {service.activeServices.length ? (
        <ServiceSelect
          isOpen={listOpen}
          activeServices={service.activeServices}
          onClose={() => setListOpen(false)}
        />
      ) : (
        listOpen && (
          <ServiceInformation
            informations={informations[service.kind]}
            onClickAway={() => setListOpen(false)}
            paperClass={classes.unactiveList}
          />
        )
      )}
    </>
  );
};

export default MenuProductElement;
