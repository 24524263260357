import {
  Button,
  ClickAwayListener,
  Container,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { guid } from 'services/Utilities';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';

const useStyles = makeStyles(theme => {
  return {
    element: {
      paddingLeft: theme.spacing(1),
    },
    icon: {
      width: '32px',
      minWidth: '32px',
      color: '#E85312',
    },
    text: {
      '& span': {
        fontSize: '12px',
      },
    },
    actions: {
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    actionButton: {
      backgroundColor: '#E85312',
      color: '#FFF',
      textTransform: 'none',
      borderRadius: theme.spacing(3),
      fontSize: 12,
    },
  };
});

const ServiceInformation = props => {
  const classes = useStyles();

  const launchChat = () => {
    props.onClickAway();
    window.hubspot.messages.EXPERIMENTAL_API.requestWidgetOpen();
  };

  return (
    <ClickAwayListener onClickAway={props.onClickAway}>
      <Paper className={props.paperClass}>
        <Container className={classes.product}>
          {props.children}
          {props.informations.map(info => (
            <ListItem className={classes.element} key={guid()}>
              <ListItemIcon className={classes.icon} key={guid()}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText className={classes.text} primary={info} />
            </ListItem>
          ))}
          <Container className={classes.actions}>
            <Button className={classes.actionButton} variant="contained" onClick={launchChat}>
              Contactez-nous
            </Button>
          </Container>
        </Container>
      </Paper>
    </ClickAwayListener>
  );
};

export default ServiceInformation;
