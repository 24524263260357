import React, { useState } from 'react';
// material-ui components
import { makeStyles, Paper, Typography, Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { acceptInvitation } from 'services/Invitation';

// MakeStyles
const useStyles = makeStyles(theme => ({
  notif: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  text: {
    fontSize: '0.8rem',
  },
  button: {
    textTransform: 'none',
  },
}));

/**
 * Notification Component
 */
const Notification = ({ notification }) => {
  const { notif, text, button } = useStyles();
  const [open, setOpen] = React.useState(false);
  const [displayNotification, setDisplayNotification] = useState(true);

  const confirmInvitation = id => {
    acceptInvitation(id).then(() => {
      setOpen(true);
      setDisplayNotification(false);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {displayNotification && (
        <Paper key={notification.id} className={notif}>
          <Typography className={text}>
            Vous êtes invité à participer à l&apos;espace collaboratif de{' '}
            {notification.instance.name}
          </Typography>
          <Button className={button} onClick={() => confirmInvitation(notification.id)}>
            Accepter
          </Button>
        </Paper>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Vous avez accepté l&apos;invitation
        </Alert>
      </Snackbar>
    </>
  );
};
export default Notification;
