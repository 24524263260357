import React from 'react';
// material-ui components
import { makeStyles, Typography, Container } from '@material-ui/core';
import Notification from 'components/Notification';

// MakeStyles
const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(0),
    color: '#005369',
  },
  noNotifications: {
    textAlign: 'center',
    '& img': {
      maxWidth: '100px',
    },
  },
}));

/**
 * MenuNotifications Component
 */
const MenuNotifications = ({ data }) => {
  const { title, noNotifications } = useStyles();

  return (
    <div>
      <p className={title}>Notifications</p>
      {data.length === 0 ? (
        <Container className={noNotifications}>
          <img src="assets/images/notification.png" alt="pas de notifications" />
          <Typography>Aucune notification pour l&apos;instant</Typography>
        </Container>
      ) : (
        data.map(notification => <Notification key={notification.id} notification={notification} />)
      )}
    </div>
  );
};
export default MenuNotifications;
