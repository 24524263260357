import React from 'react';
import { ExpansionPanelSummary, makeStyles, Typography, withStyles } from '@material-ui/core';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// MakeStyle
const useStyles = makeStyles(_theme => ({
  title: {
    margin: '10px',
    color: '#005369',
  },
}));

const ExpansionPanelDetails = withStyles(_theme => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(MuiExpansionPanelDetails);

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const DashboardExpensionPanel = ({ kind, title, content }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${kind}-content`}
        id={`${kind}-header`}
      >
        <Typography variant="h6" noWrap className={classes.title}>
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{content}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default DashboardExpensionPanel;
