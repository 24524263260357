import React from 'react';
// material-ui components
import { makeStyles } from '@material-ui/core';

// MakeStyles
const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(0),
    color: '#005369',
  },
  faq: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '& a': {
      textDecoration: 'none',
      color: 'unset',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
  guide: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '& a': {
      textDecoration: 'none',
      color: 'unset',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
}));

/**
 * MenuHelp Component
 */
const MenuHelp = () => {
  const { title, faq, guide } = useStyles();

  return (
    <div>
      <p className={title}>Aide</p>
      <p className={faq}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.koba-civique.com/centre-aide/"
        >
          FAQ
        </a>
      </p>
      <p className={guide}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://storage.gra.cloud.ovh.net/v1/AUTH_a48b75d376524e10be157d875981bb6d/kc-assetsv2/Guide%20d%E2%80%99utilisation%20du%20Back%20Office%20V2.pdf"
        >
          Guide d&apos;utilisation Mon site
        </a>
      </p>
      <p className={guide}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://storage.gra.cloud.ovh.net/v1/AUTH_a48b75d376524e10be157d875981bb6d/kc-assetsv2/Koba_Civique_ma-communication-mode-emploi.pdf"
        >
          Guide d&apos;utilisation Ma communication
        </a>
      </p>
      <p className={guide}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://storage.gra.cloud.ovh.net/v1/AUTH_a48b75d376524e10be157d875981bb6d/kc-assetsv2/Koba_Civique_Mode_Emploi_Mon_Espace_Collaboratif.pdf"
        >
          Guide d&apos;utilisation Mon espace collaboratif
        </a>
      </p>
      <p className={guide}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCTCFkW-NcMbYT5Rlk6ETSQg"
        >
          Nos tutoriels
        </a>
      </p>
    </div>
  );
};
export default MenuHelp;
