import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textfield: {
    margin: theme.spacing(1, 0, 1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4A97FF',
    color: '#FFFFFF',
  },
}));

const StepOneFreemium = ({ freemium, handleChange, errors, setActiveStep }) => {
  const classes = useStyles();
  const { company, zip, email, phone, firstname, lastname } = freemium;
  const {
    company: errorcompany,
    zip: errorzip,
    email: erroremail,
    phone: errorphone,
    firstname: errorfirstname,
    lastname: errorlastname,
  } = errors;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            className={classes.textfield}
            id="company"
            name="company"
            label="Nom de ma commune"
            variant="outlined"
            onChange={handleChange}
            value={company}
            required
            fullWidth
            error={!!errorcompany}
            helperText={errorcompany}
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.textfield}
            id="zip"
            name="zip"
            label="Code postal"
            variant="outlined"
            onChange={handleChange}
            value={zip}
            required
            fullWidth
            error={!!errorzip}
            helperText={errorzip}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            className={classes.textfield}
            id="email"
            name="email"
            label="Courriel"
            variant="outlined"
            onChange={handleChange}
            value={email}
            required
            fullWidth
            error={!!erroremail}
            helperText={erroremail}
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.textfield}
            id="phone"
            name="phone"
            label="Numéro de téléphone"
            variant="outlined"
            onChange={handleChange}
            value={phone}
            required
            fullWidth
            error={!!errorphone}
            helperText={errorphone}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            className={classes.textfield}
            id="lastname"
            name="lastname"
            label="Nom"
            variant="outlined"
            onChange={handleChange}
            value={lastname}
            required
            fullWidth
            error={!!errorlastname}
            helperText={errorlastname}
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.textfield}
            id="firstname"
            name="firstname"
            label="Prénom"
            variant="outlined"
            onChange={handleChange}
            value={firstname}
            required
            fullWidth
            error={!!errorfirstname}
            helperText={errorfirstname}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.button}
            type="submit"
            onClick={() => setActiveStep(1)}
            fullWidth
          >
            Etape suivante
          </Button>
        </Grid>
        <Grid item xs={12}>
          <p>* champs obligatoires</p>
        </Grid>
      </Grid>
    </>
  );
};

StepOneFreemium.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  freemium: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default StepOneFreemium;
