import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import LinearProgress from '@material-ui/core/LinearProgress';

const Logout = () => {
  const { signOut, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    signOut();
  });

  if (isAuthenticated) return <LinearProgress color="secondary" />;

  return <Redirect to="/" />;
};

export default Logout;
