import axios from 'axios';

// TODO: For now this is not used in any React logic (components etc.)
//  However it can be used to refresh a valid token with new assigned
//  instances should we decide to add a more robust
// check
// (check `koba-connect`'s implementation for a concrete example)
const refreshAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API,
  timeout: 180000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export default refreshAxiosInstance;
