import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Divider,
  Drawer,
  Typography,
  CssBaseline,
  Toolbar,
  IconButton,
  Grid,
  Badge,
} from '@material-ui/core';
import { getInvitations } from 'services/Invitation';
// material-ui icons
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationIcon from '@material-ui/icons/NotificationsOutlined';
import HelpIcon from '@material-ui/icons/HelpOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// Import assets
import backgroundTownhall from 'assets/images/logo_koba_white.png';
// containers
import Services from 'containers/Services';
import Statistics from 'containers/Statistics';
import ReleaseAlert from 'components/ReleaseAlert';
import HomepageFooter from 'components/FooterHomepage';
import MenuSupport from 'components/Menu/Support';
import MenuProfil from 'components/Menu/Profil';
import MenuHelp from 'components/Menu/Help';
import MenuNotifications from 'components/Menu/Notifications';
import MenuProduct from 'components/Menu/Products';

// Hooks
import useServices from 'hooks/UseServices';

const drawerWidth = 240;

// MakeStyles
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: localStorage.getItem('color') ? localStorage.getItem('color') : '#E85312',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hello: {
    color: '#005369',
    padding: theme.spacing(3),
    marginLeft: '10px',
  },
  menuButtonLeft: {
    marginRight: theme.spacing(2),
  },
  menuButtonRight: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerRight: {
    flexShrink: 0,
  },
  drawerPaperRight: {
    width: drawerWidth,
    backgroundColor: '#F0F0F0',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  navGridLeft: {
    display: 'flex',
    textAlign: 'left',
  },
  navGridLeftTitle: {
    overflow: 'inherit',
    lineHeight: '2.8rem',
    fontFamily: 'Pacifico,cursive',
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  navGridMiddle: {
    textAlign: 'center',
  },
  navGridMiddleLogo: {
    maxHeight: '3rem',
  },
  navGridRight: {
    textAlign: 'right',
  },
}));

/**
 * Dashboard Container
 */
const Dashboard = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState('');
  const [notifications, setNotifications] = useState([]);
  const { services, isLoaded } = useServices();
  const fullname = localStorage.getItem('fullname');

  const statServiceNumber = useCallback(
    () =>
      services.find(service => service.kind === 'site' || service.kind === 'campaign')
        .activeServices.length,
    [services],
  );

  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getInvitations().then(({ data: fetchedNotifications }) => {
      setNotifications(fetchedNotifications);
    });
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xl={4} xs={3} className={classes.navGridLeft}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButtonLeft, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap className={classes.navGridLeftTitle}>
                Portail de services numériques
              </Typography>
            </Grid>
            <Grid item xl={4} className={classes.navGridMiddle}>
              <img src={backgroundTownhall} alt="Logo Koba" className={classes.navGridMiddleLogo} />
            </Grid>
            <Grid item xl={4} className={classes.navGridRight}>
              <IconButton
                color="inherit"
                aria-label="profil"
                edge="end"
                className={clsx(classes.menuButtonRight)}
                onClick={() => setOpenMenu('help')}
              >
                <HelpIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="profil"
                edge="end"
                className={clsx(classes.menuButtonRight)}
                onClick={() => setOpenMenu('notifications')}
              >
                <Badge color="secondary" badgeContent={notifications.length}>
                  <NotificationIcon />
                </Badge>
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="profil"
                edge="end"
                className={clsx(classes.menuButtonRight)}
                onClick={() => setOpenMenu('profil')}
              >
                <AccountCircle />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MenuProduct services={services} />
      </Drawer>
      <Drawer
        className={classes.drawerRight}
        variant="persistent"
        anchor="right"
        open={openMenu !== ''}
        classes={{
          paper: classes.drawerPaperRight,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpenMenu('')}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        {openMenu === 'profil' && <MenuProfil />}
        {openMenu === 'help' && <MenuHelp />}
        {openMenu === 'notifications' && <MenuNotifications data={notifications} />}
        <MenuSupport />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <ReleaseAlert />
        <Typography variant="h5" className={classes.hello}>
          Bonjour {fullname} !
        </Typography>
        <Services services={services} isLoaded={isLoaded} />
        {!!statServiceNumber() && <Statistics />}
        <HomepageFooter />
      </main>
    </div>
  );
};
export default Dashboard;
