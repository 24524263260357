import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(_theme => {
  return {
    imageContainer: {
      width: 110,
      margin: 'auto',
    },

    image: {
      objectFit: 'cover',
      width: '90%',
      height: '90%',
      position: 'relative',
    },

    textDiv: {
      width: '100%',
      marginTop: 5,
    },
    text: {
      marginRight: '10px',
      marginLeft: '10px',
    },
  };
});

const ServiceElement = ({ title, image }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.imageContainer}>
        <img src={image} alt="site" className={classes.image} />
      </div>
      <div className={classes.textDiv}>
        <Typography
          variant="body2"
          align="center"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {title}
        </Typography>
      </div>
    </>
  );
};

export default ServiceElement;
