import React, { useState, useEffect } from 'react';
import Axios from 'services/Axios';
// material-ui components
import { makeStyles, Collapse, Link } from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { Alert } from '@material-ui/lab';

// MakeStyles
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    backgroundColor: localStorage.getItem('color')
      ? `${localStorage.getItem('color')}19`
      : '#E8531219',
    color: localStorage.getItem('color') || '#E85312',
  },
  icons: {
    color: localStorage.getItem('color') || '#E85312',
  },
}));

export const getReleaseNote = () => Axios.get('/release_notes?scopes[last_released]');

/**
 * ReleaseAlert Component
 */
const ReleaseAlert = () => {
  const [open, setOpen] = useState(true);
  const [releaseNote, setReleaseNote] = useState({ thumbnails: '', detailed_text: '', url: '' });
  const classes = useStyles();

  useEffect(() => {
    getReleaseNote().then(({ data: releasenote }) => {
      setOpen(true);
      setReleaseNote(releasenote[0]);
    });
  }, []);

  const { thumbnails, detailed_text: detailedText, url } = releaseNote;

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          iconMapping={{ success: <InfoOutlined fontSize="inherit" className={classes.icons} /> }}
          className={classes.alert}
          action={
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close className={classes.icons} />
            </IconButton>
          }
        >
          <strong>{thumbnails}</strong>
          {detailedText}
          {url && (
            <Link href={url} target="_blank">
              Voir plus ...
            </Link>
          )}
        </Alert>
      </Collapse>
    </div>
  );
};
export default ReleaseAlert;
