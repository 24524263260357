import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Material-ui
import {
  Grid,
  makeStyles,
  TextField,
  OutlinedInput,
  Button,
  FormControl,
  InputLabel,
  Container,
  Link as MaterialLink,
  Typography,
  FormHelperText,
  Snackbar,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

// Components
import Header from 'components/Header';
import Footer from 'components/Footer';
import EndAdornment from 'components/EndAdornment';
import { register } from 'services/Auth';
import usePasswordWithComplexity from 'hooks/UsePasswordWithComplexity';
import PasswordHelper from '../PasswordHelper';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  textfield: {
    margin: theme.spacing(1, 0, 1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4A97FF',
    color: '#FFFFFF',
  },
  loginLink: {
    color: '#FF4F03',
  },
  loginText: {
    color: '#5B6B75',
    textAlign: 'center',
  },
}));

/**
 * RegisterForm Container
 */
const RegisterForm = () => {
  const classes = useStyles();
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    passwordConfirmation: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState({ password: '', email: '' });
  const [successVisible, setSuccessVisible] = useState(false);
  const {
    password,
    setPassword,
    passwordComplexity,
    isPasswordEnough,
  } = usePasswordWithComplexity();

  const handleChange = e => {
    const { name, value } = e.target;

    setUser({ ...user, [name]: value });
    setErrorMessages({ password: '', email: '' });
  };

  // If no errors on password and password confirmation
  const isFormValid = () => user.passwordConfirmation === password && isPasswordEnough();

  const handleSubmit = e => {
    e.preventDefault();

    // No action if the form is not valid
    if (isFormValid()) {
      register({ user: { ...user, password } })
        .then(_response => {
          setSuccessVisible(true);
        })
        .catch(errors => {
          // Errors from server
          const errorList = errors.response.data.errors;
          setErrorMessages({
            password: (errorList.password && errorList.password[0]) || '',
            email: (errorList.email && errorList.email[0]) || '',
          });
        });
    }
  };

  const { firstname, email, lastname, passwordConfirmation } = user;

  return (
    <>
      <Header title="Inscription" />
      <Container className={classes.paper}>
        <Snackbar open={successVisible} autoHideDuration={3000} onClose={() => history.push('/')}>
          <Alert severity="success">
            Votre inscription a bien été prise en compte ! Vous allez être redirigés sur la page de
            login.
          </Alert>
        </Snackbar>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextField
                onChange={handleChange}
                value={firstname}
                className={classes.textfield}
                name="firstname"
                label="Prénom"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs>
              <TextField
                onChange={handleChange}
                value={lastname}
                className={classes.textfield}
                name="lastname"
                label="Nom"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <TextField
            onChange={handleChange}
            value={email}
            className={classes.textfield}
            name="email"
            label="Adresse email"
            variant="outlined"
            error={!!errorMessages.email}
            helperText={errorMessages.email && `L'adresse email ${errorMessages.email}`}
            fullWidth
            required
          />
          <FormControl variant="outlined" fullWidth className={classes.textfield}>
            <InputLabel htmlFor="outlined-adornment-password">Mot de passe *</InputLabel>
            <OutlinedInput
              onChange={e => setPassword(e.target.value)}
              value={password}
              name="password"
              variant="outlined"
              required
              fullWidth
              type={showPassword ? 'text' : 'password'}
              error={!!errorMessages.password || !isPasswordEnough()}
              endAdornment={
                <EndAdornment
                  attribute={showPassword}
                  mutator={setShowPassword}
                  ariaLabel="toggle password visibility"
                />
              }
              labelWidth={120}
            />
            {errorMessages.password && (
              <FormHelperText error>Exigence de complexité non remplie</FormHelperText>
            )}
            <PasswordHelper passwordComplexity={passwordComplexity} />
          </FormControl>
          <FormControl variant="outlined" fullWidth className={classes.textfield}>
            <InputLabel htmlFor="outlined-adornment-password">
              Confirmation du mot de passe *
            </InputLabel>
            <OutlinedInput
              onChange={handleChange}
              value={passwordConfirmation}
              name="passwordConfirmation"
              variant="outlined"
              required
              fullWidth
              type={showPassword ? 'text' : 'password'}
              error={password !== passwordConfirmation}
              endAdornment={
                <EndAdornment
                  attribute={showPassword}
                  mutator={setShowPassword}
                  ariaLabel="toggle password visibility"
                />
              }
              labelWidth={240}
            />
            {password !== passwordConfirmation && (
              <FormHelperText error>Les mots de passe doivent coincider.</FormHelperText>
            )}
          </FormControl>

          <Button variant="contained" className={classes.button} type="submit" fullWidth>
            Inscription
          </Button>

          <Typography className={classes.loginText}>
            Vous avez déjà un compte ?{' '}
            <MaterialLink component={Link} to="/" className={classes.loginLink}>
              Connectez-vous
            </MaterialLink>
          </Typography>
        </form>
        <Footer />
      </Container>
    </>
  );
};

export default RegisterForm;
