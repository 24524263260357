import { useEffect, useState } from 'react';

const defaultComplexity = {
  length: false,
  number: false,
  upcase: false,
  downcase: false,
  special: false,
};

export const complexityTextMapping = {
  length: '8 caractères',
  number: '1 nombre',
  upcase: '1 majuscule',
  downcase: '1 minuscule',
  special: '1 caractère spécial (#?!@$%^&*-)',
};

const usePasswordWithComplexity = () => {
  const [password, setPassword] = useState('');
  const [passwordComplexity, setPasswordComplexity] = useState(defaultComplexity);

  // If no complexity is set to false, password is enough
  const isPasswordEnough = () => Object.values(passwordComplexity).findIndex(e => !e) < 0;

  useEffect(() => {
    const checkPasswordLength = () => password.length >= 8;
    const checkNumberPresence = () => /\d/.test(password);
    const checkUpcasePresence = () => /[A-Z]/.test(password);
    const checkdowncasePresence = () => /[a-z]/.test(password);
    const checkSpecialCharacterPresence = () => /\W/.test(password);

    const newPasswordComplexity = {
      length: checkPasswordLength(),
      number: checkNumberPresence(),
      upcase: checkUpcasePresence(),
      downcase: checkdowncasePresence(),
      special: checkSpecialCharacterPresence(),
    };

    setPasswordComplexity(newPasswordComplexity);
  }, [password]);

  return {
    password,
    setPassword,
    passwordComplexity,
    isPasswordEnough,
  };
};

export default usePasswordWithComplexity;
