import React, { useEffect } from 'react';
// material-ui components
import { makeStyles, Container, Grid, Typography, IconButton, Link } from '@material-ui/core';
import { Facebook, LinkedIn, YouTube } from '@material-ui/icons';
// import assets
import townhall from 'assets/images/townhall.png';

// MakeStyles

const useStyles = makeStyles(theme => ({
  footerImg: {
    textAlign: 'center',
    marginTop: '100px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  footerInfo: {
    backgroundColor: localStorage.getItem('color') || '#E85312',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(9),
  },
  footerSocialNetworks: {
    color: '#FFFFFF',
  },
  footerAddress: {
    color: '#FFFFFF',
    padding: theme.spacing(1, 0, 2, 2),
  },
  newsletter: {
    textAlign: 'right',
    color: '#FFFFFF',
    '& a': {
      color: '#FFFFFF',
    },
    '& label': {
      fontSize: '16px',
      color: '#FFFFFF',
      fontWeight: 'bold',
    },
    '& input[type=email]': {
      backgroundColor: '#E85312',
      border: '2px solid #FFF',
      borderRadius: '25px',
      height: '40px',
      width: '300px',
      padding: '15px',
      color: '#FFBDA0',
      marginTop: '20px',
      fontSize: '18px',
    },
    '& input[type=submit]': {
      color: '#E85312',
      height: '30px',
      border: '5px solid #FFF',
      borderRadius: '25px',
      top: '-35px',
      position: 'relative',
      right: '5px',
      cursor: 'pointer',
    },
    '& input[type=submit]:hover': {
      backgroundColor: '#FFF',
    },
    '& ul': {
      display: 'none !important',
    },
  },
}));

/**
 * HomepageFooter Component
 */
const HomepageFooter = () => {
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '2558005',
          formId: '0f681457-d4eb-460a-a91b-b308c3b29351',
          target: '#hubspotForm',
        });
      }
    });
  }, []);

  return (
    <>
      <Container className={classes.footerImg}>
        <img src={townhall} alt="Logo footer" />
      </Container>
      <Grid container className={classes.footerInfo}>
        <Grid item md>
          <div className={classes.footerSocialNetworks}>
            <IconButton color="inherit" aria-label="Facebook">
              <Link
                href="https://www.facebook.com/kobacivique/"
                target="_blank"
                className={classes.footerSocialNetworks}
              >
                <Facebook fontSize="large" />
              </Link>
            </IconButton>
            <IconButton color="inherit" aria-label="Linkedin">
              <Link
                href="https://www.linkedin.com/company/koba-civique/"
                target="_blank"
                className={classes.footerSocialNetworks}
              >
                <LinkedIn fontSize="large" />
              </Link>
            </IconButton>
            <IconButton color="inherit" aria-label="Facebook">
              <Link
                href="https://www.youtube.com/channel/UCTCFkW-NcMbYT5Rlk6ETSQg"
                target="_blank"
                className={classes.footerSocialNetworks}
              >
                <YouTube fontSize="large" />
              </Link>
            </IconButton>
            <div className={classes.footerAddress}>
              <Typography style={{ fontWeight: 'bold' }}>KOBA Civique</Typography>
              <Typography>8 rue de l&apos;Hôtel de Ville</Typography>
              <Typography>92200 Neuilly-sur-Seine</Typography>
            </div>
          </div>
        </Grid>
        <Grid item md className={classes.newsletter} id="hubspotForm" />
      </Grid>
    </>
  );
};
export default HomepageFooter;
