import Axios from './Axios';

export const getStatsAnalitycs = slug =>
  Axios.get(`analytics?_slug=${slug}`, {
    baseURL: process.env.REACT_APP_BACKOFFICE_API_URL,
    withCredentials: false,
  });

export const getStatsCampaignSms = slug =>
  Axios.get(`campaigns/sms/last/statistics?slug=${slug}`, {
    baseURL: process.env.REACT_APP_CAMPAIGNS_API_URL,
    withCredentials: false,
  });

export const getStatsCampaignEmail = slug =>
  Axios.get(`campaigns/email/last/statistics?slug=${slug}`, {
    baseURL: process.env.REACT_APP_CAMPAIGNS_API_URL,
    withCredentials: false,
  });
