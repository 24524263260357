import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Material-ui
import {
  Grid,
  makeStyles,
  TextField,
  OutlinedInput,
  Button,
  FormControl,
  InputLabel,
  Container,
  Link as MaterialLink,
  Typography,
} from '@material-ui/core';

// Components
import Header from 'components/Header';
import Footer from 'components/Footer';
import EndAdornment from 'components/EndAdornment';
import { login } from 'services/Auth';
import { useAuthContext } from 'hooks/UseAuthContext';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  textfield: {
    margin: theme.spacing(1, 0, 1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4A97FF',
    color: '#FFFFFF',
  },
  passwordLink: {
    color: '#5B6B75',
    textDecoration: 'underline',
  },
  registerLink: {
    color: '#FF4F03',
  },
  registerText: {
    color: '#5B6B75',
  },
}));

/**
 * LoginForm Container
 */
const LoginForm = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');
  const { setSession, displayToast, setDisplayToast } = useAuthContext();

  const handleChange = e => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();

    login({ user })
      .then(({ data }) => {
        setSession(data);
        setShowError(false);
      })
      .catch(() => {
        setError('Adresse e-mail ou mot de passe incorrect.');
        setShowError(true);
      });
  };

  const handleClose = () => {
    setDisplayToast(false);
  };

  const { email, password } = user;

  return (
    <>
      <Header title="Connexion" />
      <Container className={classes.paper}>
        {showError && <Alert severity="error">{error}</Alert>}
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            className={classes.textfield}
            id="email"
            name="email"
            label="Adresse email"
            variant="outlined"
            onChange={handleChange}
            value={email}
            required
            fullWidth
          />
          <FormControl variant="outlined" fullWidth className={classes.textfield}>
            <InputLabel htmlFor="outlined-adornment-password">Mot de passe *</InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={password}
              endAdornment={
                <EndAdornment
                  ariaLabel="toggle password visibility"
                  attribute={showPassword}
                  mutator={setShowPassword}
                />
              }
              labelWidth={120}
              required
              fullWidth
            />
          </FormControl>
          <Button variant="contained" className={classes.button} type="submit" fullWidth>
            Connexion
          </Button>
          <Grid container>
            <Grid item xs>
              <MaterialLink component={Link} to="/forgot" className={classes.passwordLink}>
                Mot de passe oublié ?
              </MaterialLink>
            </Grid>
            <Grid item>
              <Typography className={classes.registerText}>
                Nouveau client ?{' '}
                <MaterialLink component={Link} to="/register" className={classes.registerLink}>
                  Inscrivez-vous
                </MaterialLink>
              </Typography>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={displayToast} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Déconnexion réussie! À bientôt
          </Alert>
        </Snackbar>
        <Footer />
      </Container>
    </>
  );
};
export default LoginForm;
