import React from 'react';
// material-ui components
import { makeStyles, Container, Typography } from '@material-ui/core';
// import assets
import logo from 'assets/images/logo_koba_color.png';

// MakeStyles
const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  logo: {
    width: '40%',
  },
  h1: {
    color: '#165267',
    marginBottom: theme.spacing(3),
    fontFamily: 'Pacifico,cursive',
    fontSize: '2.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  h2: {
    color: '#165267',
    fontSize: '1.5rem',
  },
}));

/**
 * Header Component
 */
const Header = props => {
  const classes = useStyles(props);

  return (
    <Container className={classes.header}>
      <img src={logo} alt="Logo" className={classes.logo} />
      <Typography component="h1" variant="h5" className={classes.h1}>
        Portail de services numériques
      </Typography>
      <Typography component="h2" variant="h5" className={classes.h2}>
        {props.title}
      </Typography>
    </Container>
  );
};
export default Header;
