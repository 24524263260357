import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// Material-ui
import {
  makeStyles,
  withStyles,
  Container,
  Stepper,
  StepLabel,
  Step,
  StepConnector,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
// Components
import Header from 'components/Header';
import Alert from '@material-ui/lab/Alert';
import subscribeFreemium from 'services/Freemium';
import StepOneFreemium from '../Forms/StepOneFreemium';
import StepTwoFreemium from '../Forms/StepTwoFreemium';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#165267',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#165267',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#165267',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#165267',
  },
});

const ColorlibStepIcon = ({ active, completed, icon }) => {
  const classes = useColorlibStepIconStyles();

  const icons = {
    1: <CheckIcon />,
    2: <CheckIcon />,
    3: <CheckIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
};

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number,
};

/**
 * FreemiumForm Container
 */
const FreemiumForm = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Créez votre compte', 'Personnalisez votre site', 'Bienvenue'];
  const [logo, setLogo] = useState(null);
  const [dataFreemium, setDataFreemium] = useState({
    company: '',
    zip: '',
    email: '',
    phone: '',
    lastname: '',
    firstname: '',
    primary_color: '',
    secondary_color: '',
    cgv: false,
  });
  const [errors, setErrors] = useState({
    company: '',
    zip: '',
    email: '',
    phone: '',
    lastname: '',
    firstname: '',
    primary_color: '',
    secondary_color: '',
    logo: '',
  });

  const handleChange = e => {
    const { name, value, files, checked } = e.target;
    if (name === 'header') {
      setLogo(files[0]);
    } else if (name === 'cgv') {
      setDataFreemium({ ...dataFreemium, [name]: checked });
    } else {
      setDataFreemium({ ...dataFreemium, [name]: value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('instance_kind', 'site');
    if (logo) {
      formData.append('properties[header][value]', logo);
    }
    for (const [key, value] of Object.entries(dataFreemium)) {
      formData.append(`properties[${key}][value]`, value);
    }

    subscribeFreemium(formData)
      .then(_response => {
        setActiveStep(2);
      })
      .catch(errors => {
        const errorList = errors.response.data;
        setDataFreemium({ ...dataFreemium, cgv: false });
        setErrors({
          firstname: (errorList.firstname && errorList.firstname[0]) || '',
          lastname: (errorList.lastname && errorList.lastname[0]) || '',
          email: (errorList.email && errorList.email[0]) || '',
          company: (errorList.instance_name && errorList.instance_name[0]) || '',
          zip: (errorList.postal_code && errorList.postal_code[0]) || '',
          phone: (errorList.phone_number && errorList.phone_number[0]) || '',
          primary_color: (errorList.primary_color && errorList.primary_color[0]) || '',
          secondary_color: (errorList.secondary_color && errorList.secondary_color[0]) || '',
          logo: (errorList.logo && errorList.logo[0]) || '',
        });
        setActiveStep(0);
      });
  };

  return (
    <>
      <Header title="Créez gratuitement le site de votre commune" />
      <Container className={classes.paper}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 2 && (
          <Alert severity="success">
            Votre inscription a bien été prise en compte ! Votre site est en cours de préparation.
            Vous allez recevoir un courriel vous demandant de choisir votre mot de passe de
            connexion.
          </Alert>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          {activeStep === 0 && (
            <StepOneFreemium
              handleChange={handleChange}
              freemium={dataFreemium}
              errors={errors}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 1 && (
            <StepTwoFreemium
              freemium={dataFreemium}
              handleChange={handleChange}
              setActiveStep={setActiveStep}
              setFreemium={setDataFreemium}
              errors={errors}
            />
          )}
        </form>
      </Container>
    </>
  );
};
export default FreemiumForm;
