import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import Login from 'containers/Login';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <AuthContext.Consumer>
    {({ isAuthenticated }) => (
      <Route {...rest}>{isAuthenticated ? <Component /> : <Login />}</Route>
    )}
  </AuthContext.Consumer>
);

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
