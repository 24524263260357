import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const EndAdornment = ({ attribute, mutator, ariaLabel = '' }) => (
  <InputAdornment position="end">
    <IconButton aria-label={ariaLabel} onClick={() => mutator(prevAttribute => !prevAttribute)}>
      {attribute ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);

EndAdornment.propTypes = {
  attribute: PropTypes.bool.isRequired,
  mutator: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
};

export default EndAdornment;
