import React, { useState } from 'react';
// material-ui components
import { makeStyles, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import IconCheck from '@material-ui/icons/Check';

// MakeStyles
const useStyles = makeStyles(theme => ({
  checkbox: {
    backgroundColor: props => props.color || '#E85312',
    margin: theme.spacing(1),
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    padding: '0',
    textAlign: 'center',
    cursor: 'pointer',
  },
  icon: {
    marginTop: '2px',
    color: props => props.color || '#E85312',
  },
  iconChecked: {
    marginTop: '2px',
    color: '#FFF',
  },
}));

/**
 * CheckboxColor Component
 */
const CheckboxColor = props => {
  const { checkbox, icon, iconChecked } = useStyles(props);
  const [currentColor, setCurrentColor] = useState(localStorage.getItem('color'));
  const history = useHistory();

  const handleColor = color => {
    setCurrentColor(color);
    localStorage.setItem('color', color);
    history.go(0);
  };

  return (
    <Container className={checkbox} onClick={() => handleColor(props.color)}>
      {currentColor === props.color ? (
        <IconCheck className={iconChecked} />
      ) : (
        <IconCheck className={icon} />
      )}
    </Container>
  );
};
export default CheckboxColor;
