import { getCookie } from 'services/Cookie';

const {
  REACT_APP_CAMPAIGNS_URL: campaignsUrl,
  REACT_APP_BACKOFFICE_URL: backofficeUrl,
  REACT_APP_WORKSPACE_URL: workspaceUrl,
  REACT_APP_FAMILY_URL: familyUrl,
  REACT_APP_CONSULTATION_URL: consultationUrl,
  REACT_APP_ELECTIONS_URL: electionsUrl,
  REACT_APP_CITIZEN_URL: citizenUrl,
} = process.env;

export const urls = {
  campaign: campaignsUrl,
  site: backofficeUrl,
  workspace: workspaceUrl,
  eticket: familyUrl,
  copernic: consultationUrl,
  election: electionsUrl,
  citizen: citizenUrl,
};

export const formatInternalLink = ({ kind, slug }) => `${urls[kind]}?slug=${slug}`;

const internalServiceLink = activeServices => {
  if (activeServices.length === 1) {
    return formatInternalLink(activeServices[0]);
  }
  return '';
};

const externalServiceLink = kind => `${urls[kind]}/${getCookie(`${kind}_token`)}`;

export const serviceLink = (internal, activeServices) =>
  internal ? internalServiceLink(activeServices) : externalServiceLink(activeServices[0].kind);

export const informations = {
  campaign: [
    'Dynamisez vos moyens de communication avec des services adaptés',
    "Diffusez de l'information par email ou SMS",
    'Construisez vos campagnes de communication sur-mesure',
    'Gérez votre base de données citoyens',
  ],
  site: [
    "Créez un site internet à l'image de votre collectivité",
    'Soyez autonomes dans la personnalisation du site de la commune',
    'Facilitez les démarches administratives de vos administrés',
    'Faites vivre la citoyenneté dans votre commune',
  ],
  workspace: [
    'Gagnez du temps en organisant au mieux votre travail en interne',
    'Centralisez l’actualité de la commune et échangez sur les événements en cours',
    'Collaborez facilement à distance avec les différents services de la collectivité',
    'Centralisez vos contacts et vos agendas en ligne',
  ],
  eticket: [
    'Gérez tous les aspects administratifs du périscolaire',
    'Centralisez les besoins des familles : inscriptions et paiements en ligne',
    'Bénéficiez d’un module complet de facturation automatique',
    'Accédez à tout moment à l’ensemble des informations sur les enfants que vous accueillez',
  ],
  copernic: [
    'Module participatif',
    'Présentez vos premières propositions à vos habitants',
    'Invitez les habitants à poster de nouvelles propositions',
    'Enrichissez votre programme électoral quotidiennement',
  ],
  election: [
    'Organiser une élection municipale anticipée',
    'Vous nous mettez à disposition votre fichier électeurs',
    'Nous définissons ensemble votre planning',
    'Nous prenons en charge de la réception des documents jusqu’au dépôt Postal',
  ],
  citizen: [
    'Restez au contact de vous administrés en communiquant directement sur leurs téléphones portables',
    'Envoyez-leur des messages gratuitement en cas d’alerte ou d’évènement majeur',
    'Offrez à vos administrés la possibilité de vous signaler tout dysfonctionnement observé sur votre territoire',
    'Engagez vos concitoyens dans un échange moderne avec l’équipe municipale sur des sujets qui leur importent',
  ],
};
