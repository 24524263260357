import React, { useState } from 'react';
import { IconButton, makeStyles, Container, Typography } from '@material-ui/core';

import IconLock from '@material-ui/icons/Lock';

import ServiceElement from './serviceElement';
import ServiceInformation from './serviceInformation';
import { informations } from './serviceHelper';

const useStyles = makeStyles(theme => {
  return {
    service: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    container: {
      height: 'auto',
      paddingLeft: '1.5rem',
      paddingRight: '0',
    },
    button: {
      width: '180px',
      height: '180px',
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
      '&:hover, &:focus': {
        textDecoration: 'none',
      },
    },
    lock: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(1),
    },
    select: {
      display: 'block',
      textAlign: 'center',
    },
    info: {
      zIndex: '1',
      position: 'absolute',
      top: '30%',
      left: '10%',
      padding: '0',
      margin: '0',
      width: '250px',
      backgroundColor: '#F0F0F0',
    },
    product: {
      padding: '0',
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    picture: {
      width: '32px',
      float: 'left',
      marginLeft: theme.spacing(1),
    },
    title: {
      float: 'left',
      marginLeft: theme.spacing(1),
      lineHeight: '48px',
      fontWeight: 'bold',
    },
    serviceContainer: {
      opacity: 0.4,
    },
  };
});

const UnactiveComponent = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <div className={classes.service}>
      {open && (
        <ServiceInformation
          informations={informations[props.kind]}
          onClickAway={handleClick}
          paperClass={classes.info}
        >
          <Container className={classes.product}>
            <img className={classes.picture} src={props.image} alt={props.title} />
            <Typography className={classes.title}>{props.title}</Typography>
          </Container>
        </ServiceInformation>
      )}
      <IconButton className={classes.button} onClick={handleClick}>
        <IconLock className={classes.lock} />
        <Container elevation={0} className={classes.serviceContainer}>
          <ServiceElement {...props} />
        </Container>
      </IconButton>
    </div>
  );
};

export default UnactiveComponent;
