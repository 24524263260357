import React, { useState } from 'react';
import { IconButton, Link, makeStyles, Container } from '@material-ui/core';

import ServiceElement from './serviceElement';
import ServiceSelect from './serviceSelect';
import { serviceLink } from './serviceHelper';

const useStyles = makeStyles(theme => {
  return {
    service: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    container: {
      height: 'auto',
      padding: 0,
    },
    button: {
      width: '180px',
      height: '180px',
      '&:hover, &:focus': {
        textDecoration: 'none',
      },
    },
  };
});

const ActiveService = props => {
  const classes = useStyles();
  const { activeServices, internal } = props;
  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (activeServices.length > 1 && internal) {
      e.preventDefault();
      setOpen(prevOpen => !prevOpen);
    }
  };

  return (
    <div className={classes.service}>
      <IconButton
        component={Link}
        target="_blank"
        href={serviceLink(internal, activeServices)}
        onClick={handleClick}
        className={classes.button}
      >
        <Container elevation={0} className={classes.container}>
          <ServiceElement {...props} />
        </Container>
      </IconButton>
      <ServiceSelect isOpen={open} activeServices={activeServices} onClose={() => setOpen(false)} />
    </div>
  );
};

export default ActiveService;
