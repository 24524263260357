import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
// material-ui components
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';

// MakeStyles
const useStyles = makeStyles(theme => ({
  statistic: {
    margin: theme.spacing(1),
    borderColor: props => props.color || '#FFF',
    width: props => (props.width ? props.width : 250),
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
    border: '1px',
    borderStyle: 'solid',
  },
  content: {
    padding: '0',
    margin: '0',
    '&:last-child': {
      padding: '0',
    },
  },
  title: {
    backgroundColor: props => props.color || '#FFF',
    fontSize: '1rem',
    color: '#FFF',
    height: '2rem',
    lineHeight: '2rem',
    paddingLeft: theme.spacing(2),
    borderRadius: '0 0 4px 4px',
  },
}));

/**
 * StatisticCampaign Component
 */
const StatisticCampaign = props => {
  const classes = useStyles(props);
  const { data, title, width, dataset } = props;

  const [chart, setChart] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: ['#0072BB', '#F0F2F8'],
      },
    ],
  });

  const options = {
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
  };

  useEffect(() => {
    if (!Object.keys(data).length) return;

    if (dataset === 'emails') {
      const metrics = [data.uniqueOpens, data.emailsSent - data.uniqueOpens];
      setChart(prevChart => ({
        ...prevChart,
        labels: ['Ouvert(s)', 'Non ouvert(s)'],
        datasets: [{ ...prevChart.datasets[0], data: metrics }],
      }));
    }
    if (dataset === 'sms') {
      const metrics = [data.delivered, data.cost - data.delivered];
      setChart(prevChart => ({
        ...prevChart,
        labels: ['Délivrés(s)', 'Non délivré(s)'],
        datasets: [{ ...prevChart.datasets[0], data: metrics }],
      }));
    }
  }, [data, dataset]);

  return (
    <Card className={classes.statistic}>
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h2" className={classes.title}>
          {title || <Skeleton width={width || 250} />}
        </Typography>
        {chart ? (
          <Doughnut data={chart} options={options} />
        ) : (
          <Skeleton variant="rect" height={200} width={width || 250} />
        )}
      </CardContent>
    </Card>
  );
};
export default StatisticCampaign;
