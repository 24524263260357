import React, { useCallback, useEffect, useState } from 'react';
import { getStatsAnalitycs, getStatsCampaignEmail, getStatsCampaignSms } from 'services/Statistics';
// material-ui components
import { makeStyles } from '@material-ui/core';
// import components
import StatisticAnalitycs from 'components/Statistic/StatisticAnalitycs';
import StatisticCampaign from 'components/Statistic/StatisticCampaign';
import DashboardExpensionPanel from 'components/DashboardExpensionPanel';

// MakeStyle
const useStyles = makeStyles(theme => ({
  statistics: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '30px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      width: '90%',
      paddingLeft: '0px',
    },
  },
}));

/**
 * Statistics Container
 */
const Statistics = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [statEmail, setStatEmail] = useState({});
  const [statSms, setStatSms] = useState([]);
  const [siteSlug, setSiteSlug] = useState('');
  const [campaignSlug, setCampaignSlug] = useState('');

  useEffect(() => {
    setSiteSlug(localStorage.getItem('site_slug'));
    setCampaignSlug(localStorage.getItem('campaign_slug'));
    if (siteSlug) {
      getStatsAnalitycs(siteSlug).then(result => {
        const response = result.data.reports[0].data;
        setData(response.rows || []);
      });
    }
    if (campaignSlug) {
      getStatsCampaignSms(campaignSlug).then(result => {
        setStatSms(result.data || {});
      });
      getStatsCampaignEmail(campaignSlug).then(result => {
        setStatEmail(result.data || {});
      });
    }
  }, [siteSlug, campaignSlug]);

  const panelContent = useCallback(
    () => (
      <div className={classes.statistics}>
        {siteSlug !== '' && (
          <>
            <StatisticAnalitycs
              width={450}
              color="#6C68AD"
              title="Nombre de visiteurs"
              dataset="ga:users"
              data={data}
            />
            <StatisticAnalitycs
              width={450}
              color="#6C68AD"
              title="Nombre de sessions"
              dataset="ga:sessions"
              data={data}
            />
            <StatisticAnalitycs
              width={450}
              color="#6C68AD"
              title="Nombre de pages vues"
              dataset="ga:pageviews"
              data={data}
            />
            <StatisticAnalitycs
              width={450}
              color="#6C68AD"
              title="Durée des sessions"
              dataset="ga:avgSessionDuration"
              data={data}
            />
          </>
        )}
        {campaignSlug !== '' && (
          <>
            <StatisticCampaign
              width={450}
              color="#0072BB"
              title="Dernière campagne Email : Taux d'ouverture"
              dataset="emails"
              data={statEmail}
            />
            <StatisticCampaign
              width={450}
              color="#0072BB"
              title="Dernière campagne SMS : Taux de délivrabilité"
              dataset="sms"
              data={statSms}
            />
          </>
        )}
      </div>
    ),
    [data, classes],
  );

  return (
    <DashboardExpensionPanel kind="statistics" title="Mes statistiques" content={panelContent()} />
  );
};
export default Statistics;
