import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Material-ui
import {
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Container,
  Link as MaterialLink,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// Components
import Header from 'components/Header';
import Footer from 'components/Footer';
import EndAdornment from 'components/EndAdornment';
import Axios from 'services/Axios';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  textfield: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4A97FF',
    color: '#FFFFFF',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  loginLink: {
    color: '#5B6B75',
    textDecoration: 'underline',
  },
  registerLink: {
    color: '#FF4F03',
  },
  registerText: {
    color: '#5B6B75',
  },
  home: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'unset',
  },
}));

/**
 * ResetForm Container
 */
const ResetForm = props => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValidator, setPasswordValidator] = useState('');
  const [showError, setShowError] = useState(false);
  const [showErrorServer, setShowErrorServer] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const submitPassword = () => {
    if (password !== passwordValidator) {
      setShowErrorServer(false);
      setShowError(true);
    } else {
      const params = {
        user: {
          password,
          reset_password_token: props.match.params.token,
        },
      };

      if (props.location.search.includes('campaign')) {
        params.user.mass_market = true;
      }

      Axios.put('/password', params)
        .then(_response => {
          setShowSuccess(true);
          setShowError(false);
          setShowErrorServer(false);
        })
        .catch(_e => {
          setShowError(false);
          setShowErrorServer(true);
        });
    }
  };

  return (
    <>
      <Header title="Réinitialiser votre mot de passe" />
      <Container className={classes.paper}>
        {showError && (
          <Alert severity="error" className={classes.alert}>
            Les deux mots de passe ne sont pas identiques
          </Alert>
        )}
        {showErrorServer && (
          <Alert severity="error" className={classes.alert}>
            Votre mot de passe doit contenir 8 caractères minimums et comprendre 1 caractère
            spécial, un chiffre et une majuscule (Liste des caractères spéciaux: #?!@$%^&*-)
          </Alert>
        )}
        {showSuccess && (
          <Alert severity="success" className={classes.alert}>
            Votre mot de passe a été réinitialisé. Pour vous connecter{' '}
            <Link className={classes.home} to="/">
              cliquez ici
            </Link>
          </Alert>
        )}
        {showSuccess === false && (
          <form className={classes.form}>
            <FormControl variant="outlined" fullWidth className={classes.textfield}>
              <InputLabel htmlFor="outlined-adornment-password">Mot de passe *</InputLabel>
              <OutlinedInput
                id="password"
                variant="outlined"
                required
                fullWidth
                name="password"
                onChange={event => setPassword(event.target.value)}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <EndAdornment
                    attribute={showPassword}
                    mutator={setShowPassword}
                    ariaLabel="toggle password visibility"
                  />
                }
                labelWidth={120}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth className={classes.textfield}>
              <InputLabel htmlFor="outlined-adornment-password">
                Confirmation du mot de passe *
              </InputLabel>
              <OutlinedInput
                id="password_confirm"
                variant="outlined"
                required
                name="password_validator"
                onChange={event => setPasswordValidator(event.target.value)}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <EndAdornment
                    attribute={showPassword}
                    mutator={setShowPassword}
                    ariaLabel="toggle password visibility"
                  />
                }
                labelWidth={240}
              />
            </FormControl>
            <Button
              variant="contained"
              fullWidth
              onClick={submitPassword}
              className={classes.button}
            >
              Valider
            </Button>
          </form>
        )}
        <Grid container>
          <Grid item xs>
            <MaterialLink component={Link} to="/logout" className={classes.loginLink}>
              Retour
            </MaterialLink>
          </Grid>
          <Grid item>
            <Typography className={classes.registerText}>
              Nouveau client ?{' '}
              <MaterialLink component={Link} to="/register" className={classes.registerLink}>
                Inscrivez-vous
              </MaterialLink>
            </Typography>
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </>
  );
};
export default ResetForm;
