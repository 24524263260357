import React, { useState } from 'react';
// material-ui components
import { makeStyles, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CheckboxColor from 'components/Color';

// MakeStyles
const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(0),
    color: '#005369',
  },
  name: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  email: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(2),
  },
  link: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    color: '#E85312',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  color: {
    margin: theme.spacing(1),
    width: '30px',
    height: '30px',
    borderRadius: '25%',
  },
  logout: {
    fontWeight: 'bold',
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(2),
    color: '#E85312',
    '& a': {
      color: '#E85312',
      textDecoration: 'none',
    },
  },
}));

const colors = ['#E85312', '#14529A', '#045B62', '#A71D1D', '#6C41A1', '#43575F', '#323639'];

/**
 * MenuProfil Component
 */
const MenuProfil = () => {
  const { title, name, email, link, logout, color } = useStyles();
  const [openCustomize, setOpenCustomize] = useState(false);
  const fullname = localStorage.getItem('fullname');
  const userEmail = localStorage.getItem('email');

  return (
    <>
      {openCustomize && (
        <div>
          <p className={title}>Paramètres</p>
          <p className={name}>Thèmes</p>
          <Grid container>
            {colors.map(bgColor => (
              <Grid item className={color}>
                <CheckboxColor color={bgColor} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {!openCustomize && (
        <div>
          <p className={title}>Mon compte</p>
          <p className={name}>{fullname}</p>
          <p className={email}>{userEmail}</p>
          <Button className={link} onClick={() => setOpenCustomize(true)}>
            Paramètres de mon portail
          </Button>
          <p className={logout}>
            <Link to="/logout">Déconnexion</Link>
          </p>
        </div>
      )}
    </>
  );
};
export default MenuProfil;
