import React from 'react';
// material-ui components
import { makeStyles } from '@material-ui/core';
// import assets
import townhall from 'assets/images/townhall.png';

// MakeStyles

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'absolute',
    bottom: 0,
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '25rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    zIndex: -1,
    opacity: 0.8,
    [theme.breakpoints.down('xs')]: {
      opacity: 0.5,
    },
  },
}));

/**
 * Footer Component
 */
const Footer = () => {
  const classes = useStyles();

  return <img src={townhall} alt="Logo footer" className={classes.footer} />;
};
export default Footer;
