import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { getToken, isLoggedIn } from 'services/Auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API,
  timeout: 180000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const requestHandler = request => {
  // TODO : Find a universal way to display error within application
  if (isLoggedIn()) {
    request.headers.Authorization = `Bearer ${getToken()}`;
  }
  return request;
};

// Add a request interceptor
instance.interceptors.request.use(requestHandler);

export default instance;
