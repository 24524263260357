import React, { useState, useEffect } from 'react';
import { clearCookies } from 'services/Cookie';
import { logout, isLoggedIn } from 'services/Auth';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  useEffect(() => {
    try {
      if (!isLoggedIn()) {
        localStorage.clear();
        clearCookies();
      }
      return true;
    } catch (_e) {
      return false;
    }
  }, []);

  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    try {
      return isLoggedIn();
    } catch (_e) {
      return false;
    }
  });
  const [displayToast, setDisplayToast] = useState(false);

  const setSession = currentUser => {
    setUser(currentUser);
    setIsAuthenticated(true);
  };

  const clearSession = () => {
    clearCookies();
    setUser({});
    setIsAuthenticated(false);
    setDisplayToast(true);
  };

  const signOut = () => logout().finally(() => clearSession());

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        displayToast,
        setDisplayToast,
        setSession,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
