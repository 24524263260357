import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from 'containers/Dashboard';
import Logout from 'components/Logout';
import Login from 'containers/Login';
import PrivateRoute from './PrivateRoute';

const AppRoute = () => (
  <Switch>
    <PrivateRoute exact path="/" component={Dashboard} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/reset/:token" component={Login} />
    <Route exact path="/forgot" component={Login} />
    <Route exact path="/register" component={Login} />
    <Route exact path="/freemium" component={Login} />
    <Route exact path="/offre" component={Login} />
  </Switch>
);

export default AppRoute;
